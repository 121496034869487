import { ISaveRegisterPayment } from "../interface";
import { extendMoment } from "moment-range";
import Moment from "moment";

const moment = extendMoment(Moment as any);

export function SaveRegisterPayments(this: ISaveRegisterPayment) {
  let transactions: any = [];
  this.caseDetails.map((cd: any) => {
    transactions.push({
      amount: cd.amount,
      caseId: cd.caseId,
      pid: cd.pid,
      caseType: cd.caseType
    });
  });
  return {
    voucherNo: this.voucherNo,
    paymentTypeId: this.paymentType,
    subTypeId: this.paymentMethod,
    voucherDate: moment(this.datePaid).format("YYYY-MM-DD"),
    payerBankAccount: this.accountNo,
    paymentAmount: this.voucherAmount,
    voucherRef: this.voucherReference,
    ledgerAccount: this.ledgerAccountNo,
    voucherTransactions: transactions,
    paymentTypeCode: this.paymentTypeCode,
    kid: this.kid
  };
}
